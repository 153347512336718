
import Kirbylink from './kirbylink.vue'
 
  import Swiper from 'swiper'; 

export default
{
    name: "textslideshow",
    mounted() {
        new Swiper(".swiper-text", {
            hashNavigation: {
                watchState: true,
            },
            loop: false,
            slideToClickedSlide: true,
            slidesPerView: 1,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                type: "bullets"
            }
        });
    },
    props: {
        content: Object
    },
    components: { Kirbylink }
}

